import React from 'react'
import styled from 'styled-components'
import { ArticleTag } from '../../atoms/ArticleTag'
import { Link } from '../../atoms/GatsbyLink'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

export const ArticleTagsMiniCard = styled(({ className, tags }) => (
  <ul className={className}>
    {tags?.map(({ title, emoji }) => (
      <ArticleTag key={title} emoji={emoji} title={title} />
    ))}
  </ul>
))`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ArticleTags = styled(
  ({ className, tags }) =>
    Boolean(tags.length) && (
      <span className={className}>
        {tags.map(({ title }, index) => (
          <span key={title}>
            <Link state={{ checkedTag: title }} to="/blog/">
              {title}
            </Link>
            {index + 1 === tags.length ? '' : <> · </>}
          </span>
        ))}
      </span>
    )
)`
  display: inline-block;
  white-space: normal;
  margin-top: 24px;

  & span {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: ${size.sm}) {
    margin-top: 16px;

    & span {
      white-space: normal;
    }
  }
`

export const ArticleInfo = styled(({ className, author, createdAt, title }) => (
  <div className={className}>
    <Title.H1>{title}</Title.H1>
    <Text.Average>{[`Автор: ${author}`, createdAt].join(` · `)}</Text.Average>
  </div>
))`
  margin-bottom: 24px;

  ${Title.H1} {
    margin-bottom: 16px;

    @media (max-width: ${size.xs}) {
      margin-bottom: 8px;
    }
  }

  & > ${Text.Average} {
    white-space: nowrap;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;

    & > ${Text.Average} {
      white-space: normal;
    }
  }
`
