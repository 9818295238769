import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from './Text'
import { color } from '../styles/vars/colors'
import { omit } from 'rambda'
import { size } from '../constants'

export const Label = styled.label`
  color: #16c8bb;
  border: 2px solid #16c8bb;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 10px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
  cursor: pointer;

  ${(props) =>
    !props.isMobile &&
    css`
      &:hover {
        background: #eff2f2;
      }
    `}
`

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      height: 40px;
    `,
    large: css`
      height: 48px;
    `
  }[size])

export const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;

  ${(props) =>
    !props.isMobile &&
    css`
      &:hover {
        &:checked + ${Label} {
          background: #12a096;
          border: 2px solid #12a096;
        }
      }
    `}

  &:checked + ${Label} {
    color: #ffffff;
    background: #16c8bb;
  }

  &:disabled + ${Label} {
    color: #c4c4c4;
    background: #f8f9f9;
    border: 2px solid #f8f9f9;
    pointer-events: none;
  }
`

export const Checkbox = styled(
  ({ className, value, id, disabled, group, checked, onClick }) => {
    const isMobile =
      /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
        navigator.userAgent
      )
    return (
      <div className={className}>
        <Input
          readOnly
          checked={checked}
          disabled={disabled}
          id={id}
          isMobile={isMobile}
          type="checkbox"
        />
        <Label
          data-group={group.name}
          for={id}
          isMobile={isMobile}
          onClick={onClick}
        >
          {value}
        </Label>
      </div>
    )
  }
)``

const errorCSS = ({ error }) =>
  error &&
  css`
    & label {
      border: 1px solid ${color.formItem.border.error};

      &:hover {
        border: 1px solid ${color.formItem.border.error};
        background: ${color.formItem.background.errorHover};
      }
    }
  `

const disabledCSS = ({ disabled }) =>
  disabled &&
  css`
    & label {
      color: #c4c4c4;
      border: 1px solid #f8f9f9;
      pointer-events: none;

      & svg {
        path {
          fill: #c4c4c4;
        }
      }

      &:hover,
      &:active {
        color: #c4c4c4;
        border: 1px solid #f8f9f9;
      }
    }
  `

const noBorderedCSS = ({ noBordered }) =>
  noBordered &&
  css`
    & label {
      border: 1px solid transparent !important;
    }

    & input:checked + label {
      &::before {
        content: none !important;
      }

      border: 1px solid transparent !important;
    }
  `

const shapeCSS = ({ shape }) =>
  shape === 'circle' &&
  css`
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding-bottom: 10px;

      & label:nth-child(2n) {
        border-radius: 50%;
        width: fit-content;
        width: 48px;
        height: 48px;
        padding: 15px;
        justify-content: center !important;
        font-size: 16px;
        line-height: 24px;
      }

      & input:checked + label:nth-child(2n) {
        &::before {
          border-radius: 50%;
        }
      }

      & input:focus-visible + label:nth-child(2n) {
        &::before {
          border-radius: 50%;
        }
      }

      & label:nth-child(2n + 1) {
        border: none;
        padding: 0;

        &:active {
          background: transparent !important;
        }

        &::before {
          content: none;
        }

        ${Text.Average} {
          font-weight: 600;
          width: fit-content;
          margin: auto;

          @media (max-width: ${size.sm}) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  `

export const WizardCheckbox = styled(
  ({
    className,
    id,
    name,
    value,
    Icon,
    children,
    text,
    type = 'checkbox',
    Mask,
    shape,
    ...field
  }) => (
    <div className={className}>
      <input
        {...{ id, name, type, value }}
        {...omit(['error'], field)}
        tabIndex="0"
      />
      <label htmlFor={id}>
        {Icon}
        {shape !== 'circle' && <span>{children ?? text}</span>}
      </label>
      {shape === 'circle' && (
        <label htmlFor={id}>
          <Text.Average>{children ?? text} </Text.Average>
        </label>
      )}
      {Mask}
    </div>
  )
)`
  width: 100%;
  display: block;
  height: inherit;
  position: relative;

  & label {
    display: flex;
    box-sizing: border-box;
    color: #333333;
    border: 1px solid ${color.checkbox.border};
    box-sizing: border-box;
    border-radius: 24px;
    padding: 13px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    cursor: pointer;
    height: 48px;
    position: relative;
    text-align: left;
    align-items: center;
    justify-content: center;
    height: inherit;
    white-space: nowrap;
    background: ${color.background};

    ${({ flexStart }) =>
      flexStart &&
      css`
        justify-content: flex-start;
      `}

    ${({ Icon }) =>
      Icon &&
      css`
        gap: 8px;
      `}

    &:active {
      background: #e3f5f5 !important;
    }

    ${(props) =>
      !props.isMobile &&
      css`
        &:hover {
          background: #f9fdfd;
        }
      `}
  }

  & input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  & label svg,
  label img {
    display: inline-block;
  }

  & input:checked + label {
    &::before {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      box-shadow: 0px 0px 0px 2px rgba(22, 200, 187, 1);
      border-radius: 24px;
    }

    background: #f9fdfd;
    border: 1px solid transparent;
  }

  & input:focus-visible + label {
    &::before {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      box-shadow: 0px 0px 0px 2px rgba(51, 51, 51, 1);
      border-radius: 24px;
    }

    border: 1px solid transparent;
  }

  ${disabledCSS}
  ${errorCSS}
  ${noBorderedCSS}
  ${shapeCSS}
  ${sizeCSS}
`
