import styled from 'styled-components'
import { size } from '../constants'

export const Main = styled.main`
  min-height: calc(var(--vh, 1vh) * 100 - 76px);
  background-color: #fff;

  @media (max-width: ${size.xs}) {
    min-height: calc(var(--vh, 1vh) * 100 - 56px);
  }
`
