import { isBrowser, root } from './root'
import { useEffect } from 'react'

export const useScrollEffect = (dependence) => {
  useEffect(() => {
    if (isBrowser) {
      const timeout = setTimeout(() => {
        root.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })

      return () => clearTimeout(timeout)
    }
  }, [dependence])
}
