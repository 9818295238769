import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { Montserrat } from '../constants'
import { color } from '../styles/vars/colors'

const styles = css`
  font-family: ${Montserrat};
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${color.link.default};

  &:visited,
  &:focus,
  &:hover {
    color: ${color.link.default};
  }

  &:disabled {
    color: ${color.link.disabled};
  }
`

export const Link = styled(GatsbyLink)`
  ${styles}
`
export const ExternalLink = styled.a`
  ${styles}
`
export const ArticlesLink = styled.a`
  ${styles}
  color: ${color.mdx.link.default};
  position: relative;
  z-index: 1;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${color.mdx.link.background};
    width: calc(100% + 2px);
    height: 100%;
    border-radius: 8px;
    pointer-events: none;
    z-index: -1;
  }

  &:visited,
  &:focus,
  &:hover {
    color: ${color.mdx.link.hover} !important;
  }
`
