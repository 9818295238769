import React, { useMemo } from 'react'
import Swiper from 'react-id-swiper'
import styled from 'styled-components'
import { WizardCheckbox } from '../atoms/Checkbox'
import { isBrowser } from '../atoms/root'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { size } from '../constants'
import { useSelector } from 'react-redux'

const initialParams = {
  ContainerEl: 'ul',
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 8,
  preventClicks: false,
  preventClicksPropagation: false
}

const ListOrSwiperList = styled(({ className, children }) => {
  const windowWidth = useSelector(selectDeviceSize)
  const isMobileSize = useMemo(
    () => (windowWidth ? windowWidth < 768 : false),
    [windowWidth]
  )

  return isBrowser && isMobileSize ? (
    <Swiper {...initialParams} containerClass={className}>
      {children}
    </Swiper>
  ) : (
    <ul className={className}>{children}</ul>
  )
})`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: ${size.sm}) {
    flex-wrap: nowrap;
    overflow: hidden;

    & > div {
      flex-wrap: nowrap;
      gap: 0;
    }
  }

  & li {
    width: fit-content !important;
  }

  & li > ${WizardCheckbox} > label {
    padding: 6px 8px;
    height: 32px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 40px;
  }
`

export const ArticlesTagsPanel = ({
  tagsAll,
  checkedId,
  handleCheckedAll,
  handleChecked
}) => (
  <ListOrSwiperList>
    <li>
      <WizardCheckbox
        checked={checkedId[0] === 'all'}
        id="all"
        name="all"
        onChange={handleCheckedAll}
        type="checkbox"
      >
        Все
      </WizardCheckbox>
    </li>
    {tagsAll?.map(({ id, title, emoji }) => (
      <li key={id}>
        <WizardCheckbox
          Icon={emoji}
          checked={checkedId.indexOf(String(id)) !== -1}
          id={id}
          name={id}
          onChange={handleChecked}
          type="checkbox"
        >
          {title}
        </WizardCheckbox>
      </li>
    ))}
  </ListOrSwiperList>
)
