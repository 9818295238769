import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import { ReactComponent as Next } from '../../assets/img/icons/my-pagintaion/next.svg'
import { ReactComponent as Prev } from '../../assets/img/icons/my-pagintaion/prev.svg'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

import './index.scss'

const MyPagination = ({ path, total, className = '', id = 1 }) => {
  const [navLinks, setNavLinks] = useState([])

  const wWidth = useSelector(selectDeviceSize)

  useEffect(() => {
    if (wWidth > 413) {
      if (total > 7) {
        if (id < 5) {
          setNavLinks([1, 2, 3, 4, 5, '...', total])
        } else if (id > total - 5) {
          setNavLinks([
            1,
            '...',
            total - 4,
            total - 3,
            total - 2,
            total - 1,
            total
          ])
        } else {
          setNavLinks([1, '...', id - 1, id, id + 1, '...', total])
        }
      } else {
        const templateArr = []
        for (let i = 1; i < total + 1; i++) {
          templateArr.push(i)
        }
        setNavLinks([...templateArr])
      }
    } else if (total > 5) {
      if (id < 4) {
        setNavLinks([1, 2, 3, '...', total])
      } else if (id > total - 3) {
        setNavLinks([1, '...', total - 2, total - 1, total])
      } else {
        setNavLinks([1, '...', id, '...', total])
      }
    } else {
      const templateArr = []
      for (let i = 1; i < total + 1; i++) {
        templateArr.push(i)
      }
      setNavLinks([...templateArr])
    }
  }, [id, wWidth, total])

  return (
    <nav className={`${className} pagination`}>
      <Link
        className={cn('pagination__prev pagination-btn pagination-btn_border', {
          'pagination-btn_disabled': id === 1
        })}
        to={`/${path}/?page=${id - 1}`}
      >
        {wWidth > 767 ? 'Назад' : <Prev />}
      </Link>
      <ul className="pagination__nav-list pagination-steps">
        {navLinks.map((el, i) => (
          <li key={i} className="pagination-steps__item">
            {!isNaN(el) ? (
              <Link
                className={cn('pagination-btn', {
                  'pagination-btn_active': el === id
                })}
                to={`/${path}/?page=${el}`}
              >
                {el}
              </Link>
            ) : (
              <span className="pagination-btn pagination-btn_dots">{el}</span>
            )}
          </li>
        ))}
      </ul>
      <Link
        className={cn('pagination__next pagination-btn pagination-btn_border', {
          'pagination-btn_disabled': id === total
        })}
        to={`/${path}/?page=${id + 1}`}
      >
        {wWidth > 767 ? 'Дальше' : <Next />}
      </Link>
    </nav>
  )
}

export default MyPagination
