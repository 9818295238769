import App from '../../components/App'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import MyPagination from '../../components/MyPagination'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SEO, { getSeoMedia } from '../../atoms/SEO'
import styled from 'styled-components'
import { ArticleCards } from '../../organisms/ArticleCards'
import { ArticlesTagsPanel } from '../../organisms/ArticlesTagsPanel'
import { BreadCrumbs, BreadcrumbSEO } from '../../molecules/BreadCrumbs'
import { Grid } from '../../atoms/Grid'
import { Main } from '../../atoms/Main'
import { Title } from '../../atoms/Title'
import { getQueryStringParams } from '../../helpers/queryParams'
import { graphql } from 'gatsby'
import { navigate, useLocation } from '@reach/router'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { uniq } from 'ramda'
import { useScrollEffect } from '../../atoms/useScrollEffect'
import { useSelector } from 'react-redux'

const getAllUniqTags = (nodes) => {
  if (!nodes) return []

  return uniq(nodes.flatMap(({ frontmatter }) => frontmatter.tags)).map(
    (tag, index) => ({
      ...tag,
      id: index,
      emoji: tag.emoji
    })
  )
}

const Content = styled(Grid)`
  ${Title.H1} {
    margin-bottom: 32px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 24px;
    }
  }

  ${ArticleCards} {
    margin-bottom: 56px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 40px;
    }
  }
`

const PageMain = styled(Main)`
  &::before {
    content: '';
    position: absolute;
    top: 425px;
    height: 672px;
    width: 100%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 41.95%,
        #ffffff 70.27%
      ),
      #eff5fb;
    left: 0;
    border-radius: 50% 50% 0px 0px / 25px 25px 0px 0px;
  }

  ${Content} {
    z-index: 1;
  }

  @media (max-width: ${size.lg}) {
    &::before {
      content: none;
    }
  }
`

const useNodesPagination = (allNodesState) => {
  const windowWidth = useSelector(selectDeviceSize)
  const location = useLocation()
  const { page } = getQueryStringParams(location.search)

  const limit = useMemo(() => (windowWidth > 767 ? 8 : 6), [windowWidth])

  const total = useMemo(
    () => Math.ceil(allNodesState.length / limit),
    [allNodesState, limit]
  )

  const stateNodes = useMemo(() => {
    const pageNum = Number(page)
    const data = pageNum
      ? allNodesState.filter((article, i) => {
          if (i < pageNum * limit && i >= (pageNum - 1) * limit) {
            return article
          }
        })
      : allNodesState

    if (!data?.length && pageNum) navigate(`/blog/?page=1`, { replace: true })

    return data
  }, [page, checkedId, windowWidth, limit, total, allNodesState])
}

const Articles = ({ data }) => {
  const { nodes } = data.allMdx
  const [allNodesState, setAllNodesState] = useState(nodes)
  const location = useLocation()

  useScrollEffect()

  const tagsAll = useMemo(() => getAllUniqTags(nodes), [nodes])
  const initialCheckedIdState = useMemo(
    () =>
      String(
        tagsAll.find((tag) => tag.title === location.state?.checkedTag)?.id
      ),
    [location.state]
  )

  const [checkedId, setCheckedId] = useState([initialCheckedIdState ?? 'all'])
  const [checkedTags, setCheckedTags] = useState(
    location.state?.checkedTag ? [location.state.checkedTag] : []
  )

  const handleChecked = useCallback(
    ({ target: { id, checked } }) => {
      if (checked) {
        if (checkedId[0] === 'all') setCheckedId(checkedId.shift())
        setCheckedId([...checkedId, id])
      } else if (checkedId.length === 1) {
        setCheckedId(['all'])
      } else {
        setCheckedId(checkedId.filter((item) => item !== id))
      }
    },
    [checkedId]
  )

  const handleCheckedAll = useCallback(() => {
    setCheckedId(['all'])
  }, [])

  useEffect(() => {
    if (checkedId[0] === 'all') {
      setCheckedTags([])
      return
    }
    const data = []
    tagsAll.map(({ id, title }) => {
      if (checkedId.includes(id.toString())) {
        data.push(title)
      }
    })
    setCheckedTags(data)
  }, [checkedId])

  useEffect(() => {
    const { length } = checkedTags
    if (!length) {
      setAllNodesState(nodes)
      return
    }
    const newNodes = nodes.filter((node) => {
      const tags = node.frontmatter.tags.filter(({ title }) =>
        checkedTags.includes(title)
      )
      if (tags.length) {
        return node
      }
    })

    setAllNodesState(newNodes)
  }, [checkedTags])

  return (
    <>
      <App>
        <SEO
          children={<BreadcrumbSEO />}
          description={`Полезные материалы о психологии, психотерапии, психическом 
            здоровье, отношениях и самооценке. Статьи, чек-листы, 
            памятки - самая актуальная и полезная информация на 
            сайте youtalk.ru`}
          images={getSeoMedia([
            {
              pathname: '/img/og/articles.png',
              alt: 'articles'
            }
          ])}
          title={`Статьи по психологии и психотерапии - 
            читать психологические статьи и публикации.`}
        />
        <PageMain>
          <Header autoScrollOff />
          <BreadCrumbs />
          <section className="materials">
            <Content>
              <Title.H1>Блог</Title.H1>
              <ArticlesTagsPanel
                checkedId={checkedId}
                handleChecked={handleChecked}
                handleCheckedAll={handleCheckedAll}
                tagsAll={tagsAll}
              />
              <ArticleCards stateNodes={allNodesState} />
            </Content>
          </section>
          <Footer />
        </PageMain>
      </App>
    </>
  )
}

export default Articles

export const query = graphql`
  query Articles {
    allMdx(
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        frontmatter {
          announcement
          created_at(formatString: "DD.MM.YYYY")
          color
          title
          author
          tags {
            title
          }
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
      totalCount
    }
  }
`
