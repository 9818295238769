import React from 'react'
import styled from 'styled-components'
import { ArticleTagsMiniCard } from '../molecules/Article'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from '../atoms/GatsbyLink'
import { Text } from '../atoms/Text'
import { Title } from '../atoms/Title'
import { color } from '../styles/vars/colors'
import { size } from '../constants'

const TextContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: ${color.background};

  ${Title.H4} {
    margin-bottom: 4px;
  }

  & > ${Text.Small}[data-attr="author"] {
    margin-bottom: 8px;
    color: ${color.mdx.author};
  }

  & > ${Text.Average} {
    color: ${color.text.dark};
    flex-grow: 1;
    overflow: hidden;
    margin-bottom: 8px;
    position: relative;

    &::before {
      content: '';
      background: rgb(255, 255, 255);
      z-index: 1;
      position: absolute;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 0.7959558823529411) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      bottom: -16px;
    }
  }

  & > ${Text.Small}:last-child {
    margin-top: auto;
    position: relative;
    z-index: 2;
    color: ${color.mdx.date};
  }
`

const CardImage = styled(GatsbyImage)`
  width: 100%;

  & img {
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }
`
export const CatalogCard = styled(
  ({ className, img, tags, createdAt, title, author, announcement, href }) => (
    <Link className={className} to={href}>
      <ArticleTagsMiniCard tags={tags} />
      <CardImage alt={createdAt} image={getImage(img)} />
      <TextContent>
        <Title.H4>{title}</Title.H4>
        <Text.Small data-attr="author">{`Автор: ${author}`}</Text.Small>
        <Text.Average>{announcement}</Text.Average>
        <Text.Small>{createdAt}</Text.Small>
      </TextContent>
    </Link>
  )
)`
  position: relative;
  min-height: 100%;
  text-decoration: none !important;
  overflow: hidden;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 16px;
  box-sizing: border-box;

  &:hover {
    ${Title.H4} {
      color: ${color.link.default};
    }
  }
`

export const ArticleCards = styled(({ className, stateNodes }) => (
  <div className={className}>
    {stateNodes &&
      Boolean(stateNodes.length) &&
      stateNodes.map(
        ({
          id,
          slug,
          frontmatter: { img, title, author, created_at, announcement, tags }
        }) => (
          <CatalogCard
            key={id}
            announcement={announcement}
            author={author}
            createdAt={created_at}
            href={`/${slug}`}
            img={img}
            tags={tags}
            title={title}
          />
        )
      )}
  </div>
))`
width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
gap: 24px;
--image-height: 260px;

${CatalogCard} {
  display: grid;
  grid-template-rows: var(--image-height) calc(100% - var(--image-height));
  aspect-ratio: 4 / 5;
}

${CatalogCard}:not(:nth-child(1)),
${CatalogCard}:not(:nth-child(2)) {
  width: calc((100% / 3) - 16px);

  @media (max-width: ${size.lg}) {
    --image-height: 206px;
    aspect-ratio: 2 / 3;
  }

  @media (max-width: ${size.md}) {
    width: calc(50% - 12px);
    aspect-ratio: 3 / 4;
  }
}

${CatalogCard}:nth-child(1),
${CatalogCard}:nth-child(2) {
  width: calc(50% - 12px);
  aspect-ratio: 1.18;

  @media (max-width: ${size.lg}) {
    aspect-ratio: 0.94;
    --image-height: 260px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    aspect-ratio: 1.22;
    --image-height: 404px;
  }
}

&& {
  @media (max-width: ${size.sm}) {
    display: flex:
    flex-direction: column;
    gap: 16px;

    ${CatalogCard} {
      --image-height: 206px;
      width: 100%;
    }
  }

  @media (max-width: calc(${size.xs} + 1px)) {
    ${CatalogCard} {
      aspect-ratio: 4 / 5;
    }
  }

  @media (max-width: ${size.xs}) {
    ${CatalogCard} {
      width: 100%;
      aspect-ratio: 3 / 5;
    }
  }
}
`
