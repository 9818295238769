import React from 'react'
import styled from 'styled-components'
import { color } from '../styles/vars/colors'

export const ArticleTag = styled(({ className, emoji, title }) => (
  <li className={className}>
    {emoji && <span>{emoji}</span>}
    {title}
  </li>
))`
  box-sizing: border-box;
  color: #333333;
  position: relative;
  border: 1px solid ${color.checkbox.border};
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 8px;
  background: ${color.background};
  z-index: 1;
  height: 32px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
